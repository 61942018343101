import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { FormGroup, NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  name: string;
  phone: string;
  email: string;
  businessName: string;
  message: string;
  robot;
  endpoint: string;
  //contactForm: ngForm;

  http: Http;

  constructor(http: Http) { this.http = http; }

  ngOnInit() {
    this.endpoint = 'https://modernscentsolutions.com/contact.php';
  }
  processForm(contactForm: NgForm) {
    // const allInfo = `My name is ${this.name}. My email is ${this.email}. My phone is ${this.phone}. My Business Name is ${this.businessName}. My message is ${this.message}. Robot is ${this.robot}.`;
    // alert(allInfo);
    this.sendEmail(contactForm);
  }

  sendEmail(contactForm: NgForm) {
    const postVars = {
      email : this.email,
      name : this.name,
      phone : this.phone,
      businessName : this.businessName,
      message : this.message
    };

    if (contactForm.valid) {
      contactForm.reset();
    }

    // You may also want to check the response. But again, let's keep it simple.
    this.http.post(this.endpoint, postVars)
        .subscribe(
            response => console.log(response),
            response => console.log(response)
        );
  }
}
